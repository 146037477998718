import React from "react";
import { graphql } from "gatsby";
import { Grid, List, Table } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    const businessData = this.props.data.allFisherman.edges[0].node;
    const data = { ...businessData };

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Privacy Policy</title>
          <meta name="description" content="" />
        </Helmet>
        <PageLayout
          hero={null}
        >
          <Grid
            stackable
            className="component-section-container"
            verticalAlign={"middle"}
            textAlign={"left"}
            centered
          >
            <Grid.Column width="14">
              <h2>Egg N Bird Rewards Loyalty FAQs</h2>
              <div className="faq-section">
                <h2>How do I sign up for the Loyalty Program?</h2>
                <p>There are several quick and easy ways to sign up for our Loyalty Program.  You can sign up by registering on our website or mobile app, which is available on Apple and Android devices. If you have an Egg N Bird gift card, you may also register it as your loyalty card.</p>
              </div>
              <div className="faq-section">
                <h2>All of my Eggs for the Year expired today. Why?</h2>
                <p>
                  Yearly Eggs are the total number of Eggs you earn in a single year from January 1 – December 31. Yearly Eggs will indicate your current tier and the next year’s tier. Yearly Eggs reset once a year on January 1st.
                </p>
                <p>
                Eggs Toward Next Reward always reset at 100 and therefore does not necessarily match yearly Eggs. Eggs Toward Next Reward do not indicate tier status, they simply track how many Eggs you have earned since your last reward, or how many more Eggs you need until your next reward. Each time 100 Eggs are earned, a $5 Reward will be issued to your account.
                </p>
                <p>
                Eggs you have accumulated do not expire or disappear, except by conversion of Eggs into Egg N Bird Rewards.  Yearly Eggs reset once a year on January 1 but do not affect the balance of Eggs Toward Next Reward.
                </p>
              </div>
              <div className="faq-section">
                <h2>
                Do I have to put money on my loyalty card, online account or mobile app to use my Egg N Bird Rewards account?
                </h2>
                <p>
                No, you do not have to load money on your account/card number to participate in the program, but having a preloaded card or stored value makes your in-store payments a breeze!
                </p>
              </div>
              <div className="faq-section">
                <h2>I am an Eggstra Hot Tier Member. How do I keep my Eggstra Hot status?</h2>
                <p>
                Congratulations on reaching the highest tier of Egg N Bird Rewards! To maintain your Eggstra Hot Membership status, you must continue to earn 600 Eggs between January 1 – December 31 of each year.
                </p>
              </div>
              <div className="faq-section">
                <h2>I chose my favorite Egg N Bird store during registration. Is this the only store I can use my Egg N Bird Rewards at?</h2>
                <p>
                You may use your Egg N Bird Rewards account at any of our participating Egg N Bird locations (non-participating locations include international locations).
                </p>
              </div>
              <div className="faq-section">
                <h2>
                I forgot to scan my Egg N Bird Rewards account when I visited Egg N Bird. Can I recover my Eggs?
                </h2>
                <p>
                Of course! Please contact our Guest Services team at customerservice@eggnbird.com. Please attach an image of your receipt and provide your account number. Please note that you are only able to recover Eggs within 30 days of the transaction date.
                </p>
              </div>

              <div className="faq-section">
                <h2>
                Am I able to transfer my gift card balance to my loyalty account stored value?
                </h2>
                <p>
                Absolutely! After downloading our Egg N Bird app and logging in to your account, select the ‘Add/Transfer Value” button on the main dashboard. Then select “Transfer Value” and enter your gift card number and pin. Please feel free to contact us through email at customerservice@eggnbird.com for assistance. 
                </p>
              </div>

              <div className="faq-section">
                <h2>
                When do my rewards expire?
                </h2>
                <p>
                Rewards earned through the Egg N Bird Rewards program expire after 4 weeks (28 days), unless noted otherwise. You can find out when your rewards expire by checking your reward email, mobile app, or by logging onto your account at <strong>eggnbird.com</strong>. Expired rewards will not be valid for use in stores or online.
                </p>
              </div>

              <div className="faq-section">
                <h2>
                How do I check my gift card balance?
                </h2>
                <p>
                You can check your gift card balance online by logging onto your account at <strong>eggnbird.com</strong>. Gift cards can also be registered and used as a loyalty card that accrues Eggs and rewards. Get started by registering your card at <strong>eggnbird.com</strong>.
                </p>
              </div>

              <div className="faq-section">
                <h2>
                How do I recover my username and password?
                </h2>
                <p>
                To recover your username or password, please contact Customer Service at <a href="mailto:customerservice@eggnbird.com ">customerservice@eggnbird.com </a> or click the ‘forgot password’ link on the Egg N Bird Rewards login page. 
                </p>
              </div>

              <div className="faq-section">
                <h2>
                Do I get Eggs for gift card purchases?
                </h2>
                <p>
                Unfortunately, no. Eggs are only given for eligible Egg N Bird products, like food and beverages.
                </p>
              </div>   

               <div className="faq-section">
                <h2>
                I bought a catering order, do I get any Eggs?
                </h2>
                <p>
                Yes! To receive your Eggs, please make sure you are logged into your Egg N Bird Rewards Account and order from the Egg N Bird website or mobile app. The number of Eggs you receive will vary depending on the type of catering order purchased. Eggs will NOT be earned on orders from Third Party Delivery partners (ex: ezCater).
                </p>
              </div>   

               <div className="faq-section">
                <h2>
                I have more questions, who do I contact?
                </h2>
                <p>
                We’re happy to help! Please contact us at <a href="mailto:customerservice@eggnbird.com ">customerservice@eggnbird.com </a>
                </p>
              </div>              
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
        }
      }
    }
  }
`;
